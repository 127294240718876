/* Your existing CSS styles */
body {
    font-family: 'Roboto', 'Arial', sans-serif;
    background-color: #f7f7f7;
    padding: 20px;
}

.chapter-card {
    background-color: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    transition: transform 0.3s;
    position: relative; /* To enable absolute positioning of top right content */
}

.chapter-card:hover {
    transform: scale(1.002);
}

.chapter-card input,
.chapter-card textarea {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s;
}

.chapter-card .input-container {
    position: relative;
    padding-top: 15px;
}

.chapter-card input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s;
}

.chapter-card input:focus,
.chapter-card textarea:focus {
    border-color: #003366; /* A darker shade for better contrast */
    outline: none;
}

.chapter-card input:focus + .floating-label,
.chapter-card textarea:focus + .floating-label,
.chapter-card input.filled + .floating-label,
.chapter-card textarea.filled + .floating-label {
    top: 0;
    left: 0;
    font-size: 10px;
    color: #003366;
}

.chapter-card .floating-label {
    position: absolute;
    pointer-events: none;
    left: 10px;
    top: 10px;
    transition: 0.2s ease all;
    color: #999;
}

.chapter-card button:not(.chapter-save-button) {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #0056b3; /* A darker shade for better contrast */
    color: #ffffff;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
    margin-top: 5px;
}

.chapter-card button:hover {
    background-color: #0056b3;
}

.chapter-card button:last-child {
    margin-right: 0;
}

/* CHAPTER LIST STYLES */

.chapter-list {
    display: flex;
    flex-direction: column;
    overflow:scroll;
  }
  

.chapter-list-item {
    background-color: #ffffff;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s;
}

.chapter-list-item:hover {
    background-color: #e8e8e8; /* Slightly darker for better contrast */
    cursor: pointer; /* Indicate the item is clickable */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

.chapter-list-item h3 {
    margin: 0;
    color: #333;
    font-size: 18px;
}

.chapter-list-item button {
    padding: 6px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007BFF;
    color: #ffffff;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s;
}

.chapter-list-item button:hover {
    background-color: #0056b3;
}

.chapter-list-item button.edit-btn {
    background-color: #FFC107;
    color: #333;
}

.chapter-list-item button.edit-btn:hover {
    background-color: #e0a000;
}

.chapter-list-item button.delete-btn {
    background-color: #DC3545;
}

.chapter-list-item button.delete-btn:hover {
    background-color: #c32235;
}

/* New styles for chapter number and arrows */
.chapter-card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:x-large;
}

.chapter-card input[name="chapter_number"] {
    font-size: small; /* Make the chapter number small */
    width: (auto); /* Allow the input to adjust its width */
}

/* Style for the Move Up and Move Down buttons container */
  .button-container {
    display: flex; /* Use flex display to arrange buttons horizontally */
    justify-content: flex-end; /* Align buttons to the right within the container */
    margin-top: 10px; /* Adjust margin as needed */
  }
  
  /* Style for the Move Up and Move Down buttons */
  .arrow-button {
    background-color: #007bff; /* Button background color */
    color: #fff; /* Button text color */
    border: none; /* Remove button border */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add a smooth transition effect */
    display: flex; /* Use flex display to arrange content horizontally */
    align-items: center; /* Center content vertically */
    height: 25px;
    width: 40px;
    margin-bottom: 5px;
  }
  
  .arrow-button:hover {
    background-color: #0056b3; /* Change background color on hover */
  }
  
  /* Styles for the up and down arrow icons */
  .arrow-button.up::before,
  .arrow-button.down::before {
    font-family: Arial, sans-serif; /* Specify a font to ensure consistent alignment */
    font-size: 18px; /* Adjust the arrow size as needed */
  }
  
  .arrow-button.up::before {
    content: '\25B2'; /* Unicode character for an up arrow ▲ */
  }
  
  .arrow-button.down::before {
    content: '\25BC'; /* Unicode character for a down arrow ▼ */
  }

  .add-chapter-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #0056b3; /* A darker shade for better contrast */
    color: #ffffff;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
    margin-top: 5px;
    margin-left: 30px;
}

.add-chapter-button {
    display: inline-block;
    background-color: #1ABC9C;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
    width: calc(100vw - 100px);
  }
  
  .add-chapter-button:hover {
    background-color: #16A085;
  }
  