.landing-page {
    text-align: center;
    padding: 2rem;
  }
  
  .landing-header {
    margin-bottom: 2rem;
  }
  
  .cta-button {
    background-color: #ff6347; /* Tomato */
    color: #fff;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cta-button:hover {
    background-color: #ff4500; /* OrangeRed */
  }
  
  .features-section {
    margin-top: 2rem;
  }
  