.characters-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    
    overflow-y: auto;
  }

  .character-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    height: 50vh;
    padding-right: 20px; /* To ensure content doesn't touch the scrollbar */
    overflow: auto;
    list-style: none;
}

.character-item {
  
}

.character-item h3 {
    margin-top: 0;
    color: #333;
}

.character-item p {
    margin-bottom: 1px;
}

.input-wrapper button {
    display: block;
    background-color: #1ABC9C;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .create-character-button {
    display: block;
    background-color: #1ABC9C;
    color: #fff;
    padding: 10px;
    margin: 15px 0;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .input-wrapper input {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #ccc;
    margin-right: 10px;
    color: #34495E; /* Dark Gray */
}

.create-character-container input,
.create-character-container textarea {
  overflow-y: hidden;
  resize: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #ccc;
  width: calc(100% - 20px);

}

.gender-dropdown {
    display: block;
    width: 90%;
    margin: 15px auto; /* Centering */
    padding: 10px;
    background-color: #1ABC9C; /* Matching the button color */
    text-align: center;
    border: none;
    border-radius: 5px;
    color: #fff; /* Text color */
    font-size: 16px; /* Font size, adjust as needed */
    cursor: pointer;
    appearance: none; /* Removes default dropdown styling */
    -webkit-appearance: none; /* For Safari */
    -moz-appearance: none; /* For Firefox */
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="white" d="M2 0L0 2h4zm0 5L0 3h4z"/></svg>'); /* SVG for down arrow */
    background-repeat: no-repeat;
    background-position: right 0.7em center; /* Position the arrow on the right side */
    background-size: 2% auto;
  }
  
  .gender-dropdown:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(26, 188, 156, 0.5); /* Matching the button color for focus */
  }
  
  .gender-dropdown:hover {
    background-color: #16A085; /* Matching the button hover color */
  }
  
  .gender-dropdown option {
    background-color: #1ABC9C; /* Same color as dropdown for consistency */
    color: #fff;
  }
  
  .character-list::-webkit-scrollbar {
    width: 6px;
    }

    .character-list::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    .character-list::-webkit-scrollbar-thumb {
        background: #888;
    }

    .character-list::-webkit-scrollbar-thumb:hover {
        background: #555;
    }


.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* or any other value depending on your design */
}

.character-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 75vh;
  overflow-y: auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 20px;
}

.character-item {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-width: 250px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Added for spacing */
  cursor: pointer;
  transition: transform 0.2s;
  overflow: hidden; /* Hide overflow content */
}

.character-item h3 {
  margin-top: 0;
  color: #333;
  flex-grow: 1; /* Allow the title to take up available space */
  cursor: pointer; /* Make character names clickable */
}

.character-item p {
  color: #666;
  max-height: 50px; /* Adjust based on your preference */
  overflow-y: auto; /* Make content scrollable if it exceeds the card's height */
}

.character-item:hover {
  transform: scale(1.05); /* Slightly enlarge the card on hover */
}

/* Button styling */
.create-character-button {
  display: inline-block;
  background-color: #1ABC9C;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-character-button:hover {
  background-color: #16A085;
}

.delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(0, 0, 0);
  border:#1ABC9C;
  border-radius: 30%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.delete-btn:hover {
  background-color: red;
  color: white;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #000;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Example styles, adjust according to your design requirements */
.create-character-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

.input-wrapper {
  margin-bottom: 15px;
}

.input-wrapper label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-wrapper input,
.input-wrapper textarea,
.input-wrapper select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.create-character-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.create-character-button:hover {
  background-color: #45a049;
}

/* Add more styles as needed */
