.chapter-control-button {
    border: none;
    background-color: #f0f0f0;
    padding: 10px 15px; /* Slightly increased padding for a larger click area */
    margin: 0 12px; /* Slightly increased margin to separate buttons */
    cursor: pointer;
    border-radius: 4px; /* Slightly more rounded corners */
    font-size: 1em; /* Increased font size for better readability */
    transition: background-color 0.2s, box-shadow 0.2s, transform 0.1s; /* Smooth transitions for visual effects */
}

.chapter-control-button:hover {
    background-color: #e6e6e6; /* Lighter shade on hover for better contrast */
    box-shadow: 0 4px 6px rgba(0,0,0,0.15); /* More pronounced shadow for depth */
    transform: translateY(-2px); /* Slight lift effect */
}

.chapter-control-button:focus {
    outline: none; /* Remove default focus outline */
    box-shadow: 0 0 0 2px #007bff; /* Custom focus style to improve accessibility */
}

.chapter-control-button.chapter-save-button {
    background-color: #4CAF50;
    color: white;
    margin-bottom: 20px; /* Increase margin to ensure spacing */
    /* Added .chapter-control-button to increase specificity */
}

.delete-button {
    background-color: #F44336;
    color: white;
}

.move-up-button {
    background-color: #2196F3;
    color: white;
}

.move-down-button {
    background-color: #2196F3;
    color: white;
}

/* Adding ARIA labels for accessibility */
.chapter-control-button[aria-label="Save chapter"] {
    /* No additional styles needed here, but ARIA label should be added in JSX */
}

.chapter-control-button[aria-label="Delete chapter"] {
    /* No additional styles needed here, but ARIA label should be added in JSX */
}

.chapter-control-button[aria-label="Move chapter up"] {
    /* No additional styles needed here, but ARIA label should be added in JSX */
}

.chapter-control-button[aria-label="Move chapter down"] {
    /* No additional styles needed here, but ARIA label should be added in JSX */
}
