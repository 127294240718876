.chapter-card {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 20px;
}

.chapter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expand-collapse-btn {
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
}

.chapter-details {
    margin-top: 10px;
    margin-right: 20px;
}

.character-selection-group {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border: 1px solid #e0e0e0; 
    padding: 15px;
    border-radius: 8px; 
    margin-right: 20px;
}

/* Add additional styles here */
.theme-editor-group {
    border: 1px solid #e0e0e0;
    padding: 15px;
    margin-top: 10px;
    border-radius: 8px;
}

.delete-theme-button {
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    margin-bottom: 10px;
}