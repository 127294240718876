/* General styling */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
  }
  
  .welcome {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* or any other value depending on your design */
  }
  
  .project-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 75vh;
    overflow-y: auto;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 20px;

  }
  
  .cta {
    text-align: center;
    padding: 40px 0;
    margin-bottom: 20px; /* space between the CTA and the button */
  }
  
  .project-card {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-width: 250px;
    min-height: 200px; 
    cursor: pointer;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will ensure the content inside is spaced out evenly */
    overflow: hidden; /* Hide overflow content */
  }
  
  .project-card h2 {
    margin-top: 0;
    color: #333;
    flex-grow: 1; /* Allow the title to take up available space */
  }
  
  .project-card p {
    color: #666;
    overflow-y: auto; /* Make content scrollable if it exceeds the card's height */
    max-height: 300px; /* Adjust based on your preference */
  }
  
  .project-card:hover {
    transform: scale(1.05); /* Slightly enlarge the card on hover */
  }

  /* Button styling */
  .create-project-btn {
    display: inline-block;
    background-color: #1ABC9C;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .create-project-btn:hover {
    background-color: #16A085;
  }
  
  .cta h2 {
    color: #333;
  }
  
  .cta p {
    color: #666;
  }
  
  .delete-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: rgb(0, 0, 0);
    border:#1ABC9C;
    border-radius: 30%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .delete-btn:hover {
    position: absolute;
    background-color: red;
    top: 10px;
    right: 10px;
    color: rgb(0, 0, 0);
    border:#1ABC9C;
    border-radius: 30%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #000;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  