
.character-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    padding-right: 10px; /* To ensure content doesn't touch the scrollbar */
    overflow: auto;
    resize: vertical;
    max-height: 80vh;
    height: 60vh;
  }
  
  
  .character-details label {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 18px; /* Using the same font size as .prompt */
  }
  
  .character-details input,
  .character-details textarea {
    overflow-y: hidden;
    resize: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #ccc;
    width: calc(100% - 20px);

  }
  
  .save-button {
    margin-top: 20px;
    padding: 10px 15px;
    border: none;
    background: #1ABC9C;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    cursor: pointer;
    width: calc(100% - 10px);
  }
  
  .character-details button:hover {
    background: #5aa162;
  }

  .character-details button{
    display: inline-block;
    background-color: #1ABC9C;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .create-character-button:hover {
    background-color: #16A085;
  }

  .trait-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
        padding-right: 10px; /* To ensure content doesn't touch the scrollbar */
        overflow: auto;
        resize: vertical;
        list-style: none;
    }

    
  .trait-button {
    margin-right: 25px;
}