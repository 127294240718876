.character-selection {
    margin: 10px 0;
}

.character-search {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.character-search input {
    flex-grow: 1;
    padding: 5px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.character-search button {
    padding: 5px 10px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px; /* Increased margin to separate the clear button from the input field */
}

.character-search button:hover {
    background-color: #e6e6e6;
}

.character-list-container {
    max-height: 200px; /* Adjust as needed */
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 10px;
}

.character-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.character-list li {
    padding: 5px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.character-list li:last-child {
    border-bottom: none;
}

.character-list li:hover {
    background-color: #f0f0f0;
}

.selected-characters {
    margin-top: 10px;
}

.selected-character {
    display: inline-block;
    padding: 5px;
    margin-right: 5px;
    background-color: #d1ecf1;
    border: 1px solid #bee5eb;
    border-radius: 4px;
    margin-bottom: 5px;
}

.selected-character button {
    margin-left: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.selected-character button:hover {
    color: red;
}
