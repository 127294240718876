
.project-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-details-expanded label,
.project-details-collapsed label {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin-top: 10px;
  font-weight:bolder;
}

.project-details-expanded input,
.project-details-expanded textarea,
.project-details-collapsed input,
.project-details-collapsed textarea  {
  overflow-y: hidden;
  resize: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #ccc;
  width: calc(100% - 35px);
}

.save-button {
  padding: 10px 15px;
  border: none;
  background: #1ABC9C;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  cursor: pointer;
  width: calc(100% - 10px);
  margin-bottom: 15%;
}

.project-details button:hover {
  background: #5aa162;
}

.project-details-expanded {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  max-height: calc(50vh - 50px);
  width: 100%;
  transition: max-height 0.3s ease-out;
}

.project-details-collapsed {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  max-height: calc(100vh - 60px - 50px); /* Adjust as needed */
  transition: max-height 0.3s ease-out;
}

