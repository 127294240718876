/* App.css */

/* CSS Variables for Theming */
:root {
  --primary-color: #007bff; /* Primary color for your app */
  --secondary-color: #6c757d; /* Secondary color */
  --background-color: #f8f9fa; /* Background color */
  --text-color: #333; /* Primary text color */
  --font-family: 'Arial', sans-serif; /* Primary font family */
  --border-radius: 4px; /* Border radius for elements */
}

/* Global Styles */
body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  padding-right: 10px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Times New Roman', serif;
}

p {
  line-height: 1.5;
  margin: 0 0 1em;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.mb-1 { margin-bottom: 1rem; }
.mt-1 { margin-top: 1rem; }
.mx-auto { margin-left: auto; margin-right: auto; }

.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  color: white;
  background-color: var(--primary-color);
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: darken(var(--primary-color), 10%);
}

/* Responsive Design */
@media (max-width: 768px) {
  .container {
    padding: 0 15px;
  }
}
